<template>
  <div class="made-concept">
    <div class="made-concept-content">
      <div class="contact-section">
        <contact-form
            title="联系代理"
            contentLabel="留言"
            :onSubmit="handleFormSubmit">
        </contact-form>
      </div>

      <!--      <div class="image-slider-section">
              <swiper
                  class="made-concept-swiper"
                  :modules="modules"
                  :autoplay="{delay: 3500, disableOnInteraction: false}"
                  :loop="true"
              >
                <swiper-slide v-for="(image, index) in images" :key="index">
                  <img class="made-concept-img" :src="image" alt="怀国酒业留言">
                </swiper-slide>
              </swiper>
            </div>-->
    </div>
    <div class="additional-content">
      <!-- 在这里添加其他的图片或内容 -->
      <img src="@/assets/join/pc/01.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/02.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/03.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/04.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/05.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/06.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/07.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/08.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/09.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/10.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/11.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/12.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/13.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/14.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/15.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/16.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/17.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/18.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/19.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/20.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/21.png" alt="怀国酒业招商" class="additional-img">
      <img src="@/assets/join/pc/22.png" alt="怀国酒业招商" class="additional-img">
    </div>
    <div class="footer-info">
      <p>
        本页面所涉及的加盟信息仅供参考，加盟投资存在一定风险，投资者需根据自身情况谨慎决策。投资有风险，加盟需谨慎。
        具体的招商方案、合作细节及条款，请通过官方联系方式与我们确认，确保信息准确无误。
      </p>
    </div>
  </div>
</template>

<script>
//import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import ContactForm from "@/components/join/ContactForm";
import axios from 'axios';
import { message } from "ant-design-vue";

export default {
  name: "JoinPage",
  components:{
    //Swiper,
    //SwiperSlide,
    ContactForm
  },
  data() {
    return {
      images: [
        require('@/assets/join/join.jpg'),
      ],
    };
  },
  setup() {
    return { modules: [Autoplay] };
  },
  mounted() {
    this.updateJoinBasedOnHash();
  },
  watch: {
    '$route': function () {
      this.updateJoinBasedOnHash();
    }
  },
  methods: {
    handleFormSubmit(formData, callback) {
      console.log("Form submitted with:", formData);
      axios.post('https://www.guorundianshang.top:9999/notes/erpCustomerNotes/add', formData)
          .then(response => {
            message.success('提交成功！我们会尽快联系您！', 3);
            console.log(response.data);
          })
          .catch(error => {
            message.error('提交失败: ' + error.message);
            console.error(error);
          });
      setTimeout(() => {
        if (typeof callback === 'function') {
          callback();
        }
      }, 3000);
    },
    updateJoinBasedOnHash() {
      if (this.$route.hash === '#made') {
        this.showMade = true;
      } else if (this.$route.hash === '#acting') {
        this.showMade = false;
      }
    },
    toggleJoinPage(type) {
      this.showMade = (type === 'made');
    },
  }
}
</script>

<style scoped>
.made-concept {
  background-image: url('~@/assets/product/product-background.jpg');
  padding: 20px;
}

.made-concept-content {
  max-height: 600px;
  display: flex;
  gap: 20px;
}

.contact-section {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #f0f0f0; /* 添加分隔线 */
}

.image-slider-section {
  flex: 2;
  padding: 20px;
}

.additional-content {
  width: 100%;
}

.footer-info {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.footer-info p {
  margin: 0;
  line-height: 1.6;
}

.made-concept-swiper {
  width: 100%;
  height: auto;
}

.made-concept-img {
  width: 100%;
  object-fit: cover;
}

.additional-img {
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .made-concept-content {
    flex-direction: column; /* 移动端改为上下布局 */
    gap: 10px;
  }

  .contact-section {
    border-right: none; /* 移除移动端的分隔线 */
    border-bottom: 1px solid #f0f0f0; /* 添加底部分隔线 */
  }

  .contact-section,
  .image-slider-section,
  .additional-content {
    padding: 10px;
  }

  .made-concept-swiper {
    width: 100%;
    height: 300px;
  }

  .made-concept-img {
    height: auto;
  }

  .additional-img {
    width: 100%;
  }
}
</style>
